import { ErrorBoundary } from "@sentry/react";
import ApplicationController, { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";

type PanelProps = {
  title: React.ReactNode;
  buttons?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  titleClassName?: string;
};

const ErrorState = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="-translate-y-5 text-center">
        <p className="mb-4 text-lg font-medium">Something went wrong</p>
        <p className="text-sm text-gray-600">Please try again later</p>
      </div>
    </div>
  );
};

export class PanelController extends ApplicationController<{ buttons: React.ReactNode }, PanelProps> {
  static initialState = {
    buttons: null,
  };
  buttonsFromProps = true;

  override async changeProps(newProps: PanelProps) {
    await super.changeProps(newProps);
    if (this.buttonsFromProps) {
      this.setState({ buttons: newProps.buttons });
    }
  }

  actionSetButtons(buttons: React.ReactNode) {
    if (buttons) {
      this.buttonsFromProps = false;
      this.state.buttons = buttons;
    } else {
      this.buttonsFromProps = true;
      this.state.buttons = this.props.buttons;
    }
  }
}

const PanelComponent = ApplicationView(({ title, titleClassName, className, children }: PanelProps) => {
  const controller = PanelController.use();
  const { buttons } = controller.state;

  return (
    <div className={clsx("panel group rounded-lg bg-white p-6 shadow-sm", className)}>
      <div className="flex h-full flex-col">
        <div className="mb-4 flex items-center justify-between">
          <h2 className={clsx("text-lg font-medium", titleClassName)}>{title}</h2>
          <div>{buttons}</div>
        </div>
        <div className="grow">
          <ErrorBoundary fallback={<ErrorState />}>{children}</ErrorBoundary>
        </div>
      </div>
    </div>
  );
});

export const Panel = PanelController.scope(PanelComponent);
