import clsx from "clsx";
import { Spinner } from "./Spinner";
import { omit } from "@mixitone/util";
import React from "react";
import { twMerge } from "tailwind-merge";

export type ButtonKind =
  | "primary"
  | "secondary"
  | "accent"
  | "plain"
  | "danger"
  | "action"
  | "dropdown"
  | "select";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  kind?: ButtonKind;
  dark?: boolean;
  round?: boolean;
  spinner?: boolean;
  className?: string;
  noBorder?: boolean;
  groupPosition?: "left" | "middle" | "right";
}

function colorToClasses(
  color: "brand" | "green" | "red" | "secondary" | "action" | "dropdown" | "select",
  groupPosition?: "left" | "middle" | "right",
  round?: boolean,
  dark?: boolean,
): string[] {
  let colors: string[] = [];

  switch (color) {
    case "brand":
      colors = [
        `bg-brand-700`,
        `bordershadow-brand-fixed-800`,
        `hover:bordershadow-brand-fixed-1000`,
        `hover:bg-brand-600`,
        `focus-visible:outline-brand-600`,
        "text-white",
        "hover:text-white",
        "active:border active:border-brand-400",
      ];

      if (!groupPosition) {
        colors.push(
          `border`,
          `border-t-brand-600`,
          `border-r-brand-700`,
          `border-b-brand-700`,
          `border-l-brand-600`,
          `hover:border-t-brand-700`,
          `hover:border-r-brand-800`,
          `hover:border-b-brand-800`,
          `hover:border-l-brand-700`,
        );
      }
      return colors;
    case "red":
      colors = [
        `bg-red-700`,
        `bordershadow-red-fixed-800`,
        `hover:bordershadow-red-fixed-1000`,
        `hover:bg-red-600`,
        `focus-visible:outline-red-600`,
        "text-white",
      ];

      if (!groupPosition) {
        colors.push(
          `border`,
          `border-t-red-600`,
          `border-r-red-700`,
          `border-b-red-700`,
          `border-l-red-600`,
          `hover:border-t-red-700`,
          `hover:border-r-red-800`,
          `hover:border-b-red-800`,
          `hover:border-l-red-700`,
        );
      }
      return colors;
    case "green":
      if (round) {
        colors = [
          "text-white stroke-white fill-white border-green-500 bg-green-700 shadow-green-800/40 hover:border-green-600 hover:bg-green-600",
        ];
      } else {
        colors = [
          "bg-green-600",
          "bordershadow-green-fixed-700",
          "hover:bordershadow-green-fixed-900",
          "hover:bg-green-600",
          "hover:shadow-lg",
          "focus-visible:outline-green-500",
          "text-white",
          "hover:text-white",
        ];
      }

      if (!groupPosition) {
        colors.push(
          "border",
          "border-t-green-500",
          "border-r-green-600",
          "border-b-green-600",
          "border-l-green-500",
          "hover:border-t-green-600",
          "hover:border-r-green-700",
          "hover:border-b-green-700",
          "hover:border-l-green-600",
        );
      }
      return colors;
    case "secondary":
      colors = [
        "bg-white dark:bg-transparent",
        "bordershadow-brand-fixed-700",
        "hover:bordershadow-brand-fixed-900",
        "hover:bg-brand-50 dark:hover:bg-transparent",
        "focus-visible:outline-brand-500",
        "text-brand-700 dark:text-brand-400",
        "dark:hover:text-brand-200",
        "active:text-brand-100",
        "shadow-sm shadow-brand-500",
        "border border-brand-700",
        "active:border-brand-400",
      ];

      return colors;
    case "action":
      colors = [
        "bg-gray-100",
        "bordershadow-gray-fixed-400",
        "hover:bordershadow-gray-fixed-700",
        "hover:bg-gray-50",
        "focus-visible:outline-gray-300",
        "text-gray-600",
        "shadow-sm shadow-gray-300",
        "px-2",
        "py-1",
        "border border-gray-400",
      ];

      return colors;
    case "dropdown":
      colors = ["rounded-lg border py-2 pl-3 pr-10 text-left shadow-md ui-open:border-gray-100"];
      return colors;
    case "select":
      colors = [
        "mt-1 py-2 pl-2 pr-7 block w-full border border-gray-300 stroke-gray-500 rounded text-left min-h-[42px]",
      ];
      return colors;
  }
}

export function buttonClasses(
  kind: ButtonKind,
  className: string,
  groupPosition?: "left" | "middle" | "right",
  round?: boolean,
  dark?: boolean,
) {
  let colorClasses = [
    "fill-white",
    // "stroke-white",
    "disabled:bg-gray-300",
    "disabled:text-gray-500",
    "disabled:fill-gray-500",
    "disabled:stroke-gray-500",
  ];

  if (!groupPosition) {
    colorClasses.push(
      `disabled:border-t-gray-400`,
      `disabled:border-r-gray-400`,
      `disabled:border-b-gray-400`,
      `disabled:border-l-gray-400`,
    );
  }

  switch (kind) {
    case "plain": {
      return className;
    }
    case "danger": {
      colorClasses.push(...colorToClasses("red", groupPosition, false, dark));
      break;
    }
    case "accent": {
      colorClasses.push(...colorToClasses("green", groupPosition, round, dark));
      break;
    }
    case "secondary":
    case "action":
    case "dropdown":
    case "select": {
      colorClasses.push(...colorToClasses(kind, groupPosition, false, dark));
      break;
    }
    default: {
      colorClasses.push(...colorToClasses("brand", groupPosition, false, dark));
      break;
    }
  }

  if (round) {
    colorClasses.push("h-[55px] w-[55px] rounded-full font-semibold tracking-wide shadow-md");
  }

  const classes = [
    "relative",
    "inline-flex",
    "items-center",
    "px-3",
    "py-2",
    "space-x-2",
    "leading-4",
    "text-center",
    "transition-all",
    "duration-200",
    "ease-out",
    "rounded",
    "shadow-sm",
    "outline-none",
    "cursor-pointer",
    "font-normal",
    "outline-0",
    "focus-visible:outline-4",
    "focus-visible:outline-offset-1",
    "disabled:cursor-not-allowed",
    "text-sm",
  ];

  return twMerge(classes, colorClasses, className);
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const classes = twMerge(
    "relative",
    buttonClasses(
      props.kind || "primary",
      props.className || "",
      props.groupPosition,
      props.round,
      props.dark,
    ),
  );

  return (
    <button {...omit(props, ["spinner", "groupPosition", "round"])} className={classes} ref={ref}>
      <span className={clsx("mx-auto flex items-center gap-1", { "opacity-30": props.spinner })}>
        {/* {props.spinner && <Spinner size={16} />}
        {!props.spinner && props.children} */}
        {props.children}
      </span>
      {props.kind === "select" && (
        <span className={`absolute right-2 top-1/2 -translate-y-1/2`}>
          <svg className={`h-4 w-4`} viewBox="0 0 20 20">
            <path d="M5.05 7.05a.5.5 0 01.71 0L10 11.29l4.24-4.24a.5.5 0 01.71.71l-4.5 4.5a.5.5 0 01-.71 0l-4.5-4.5a.5.5 0 010-.71z" />
          </svg>
        </span>
      )}
      {props.spinner && (
        <div className="absolute left-0 flex items-center justify-center w-full" style={{ margin: 0 }}>
          <Spinner size={16} />
        </div>
      )}
    </button>
  );
});

export { Button };
