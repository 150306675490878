export function microtaskScheduler() {
  const reactions = new Set<Function>();
  let scheduled = false;

  return (reaction: Function) => {
    reactions.add(reaction);
    if (scheduled) return;
    scheduled = true;
    queueMicrotask(() => {
      scheduled = false;
      reactions.forEach((reaction) => reaction());
      reactions.clear();
    });
  };
}
