import { size, useFloating } from "@floating-ui/react";
import cx from "clsx";
import ApplicationController, { ApplicationView } from "@mixitone/mvc";
import { omit } from "@mixitone/util";
import React, { KeyboardEvent, useCallback } from "react";
import { Input } from "./Input";

interface State<T> {
  suggestions: T[];
  renderedSuggestions: string[];
  filteredSuggestions: IndexedSuggestions;
  open: boolean;
  value: string;
  filter: string;
  highlightedIndex: number;
}

interface Props<T> {
  suggestions: T[];
  blankSuggestion?: T;
  value?: string;
  onSelect?: (value: T) => void;
  renderSuggestion?: (suggestion: T) => string;
}

type IndexedSuggestion = [number, string];
type IndexedSuggestions = IndexedSuggestion[];

class AutocompleteController<T> extends ApplicationController<State<T>, Props<T>> {
  static override initialState: State<any> = {
    suggestions: [],
    renderedSuggestions: [],
    filteredSuggestions: [],
    open: false,
    filter: "",
    value: "",
    highlightedIndex: -1,
  };

  override async initialize(props: Props<T>) {
    this.props = props;
    this.state.suggestions = props.suggestions || [];
    this.state.renderedSuggestions = this.state.suggestions.map((suggestion) =>
      this.renderSuggestion(suggestion),
    );
    this.state.value = props.value || "";
  }

  override async changeProps(newProps: Props<T>) {
    if (newProps.value !== this.state.value) {
      this.state.value = newProps.value || "";
    }
  }

  renderSuggestion(suggestion: T): string {
    if (this.props.renderSuggestion) {
      return this.props.renderSuggestion(suggestion);
    }
    return String(suggestion);
  }

  filterSuggestions() {
    let suggestions = this.state.renderedSuggestions.map(
      (suggestion, index) => [index, suggestion] as IndexedSuggestion,
    );

    if (this.state.filter.length > 0) {
      const [suggestedByFirst, remaining] = suggestions.reduce(
        (acc, suggestion) => {
          if (suggestion[1].toLowerCase().startsWith(this.state.filter.toLowerCase())) {
            acc[0].push(suggestion);
          } else {
            acc[1].push(suggestion);
          }
          return acc;
        },
        [[], []] as [IndexedSuggestions, IndexedSuggestions],
      );

      const suggestedByIncludes = remaining.filter((suggestion) =>
        suggestion[1].toLowerCase().includes(this.state.filter.toLowerCase()),
      );

      suggestions = [...suggestedByFirst, ...suggestedByIncludes];
    }

    if (this.props.blankSuggestion) {
      suggestions.unshift([-1, this.renderSuggestion(this.props.blankSuggestion)]);
    }

    this.state.filteredSuggestions = suggestions;
  }

  actionSetFilter(filter: string) {
    this.setState({ filter });
    this.filterSuggestions();
  }

  async actionSetValue(value: string) {
    this.setState({
      value,
      open: true,
    });
    this.actionSetFilter(value);
    if (this.state.highlightedIndex === -1) {
      this.state.highlightedIndex = 0;
    }
  }

  async actionSelectSuggestion(suggestion: IndexedSuggestion) {
    this.state.value = suggestion[1];
    this.state.open = false;
    if (this.props.onSelect) this.props.onSelect(this.state.suggestions[suggestion[0]]);
  }

  async actionFocus() {
    this.setState({ open: this.state.value.length > 0 });
    this.actionSetFilter(this.state.value);
  }

  async actionClose() {
    this.state.open = false;
  }

  async actionKeyDown() {
    if (!this.state.open) {
      this.state.open = true;
    }
    const index = this.state.highlightedIndex + 1;
    if (index >= this.state.filteredSuggestions.length) return;

    this.state.highlightedIndex = index;
    const suggestion = this.state.filteredSuggestions[index];
    this.state.value = suggestion[1];
  }

  async actionKeyUp() {
    if (!this.state.open) this.state.open = true;
    const index = this.state.highlightedIndex - 1;
    if (index < 0) return;

    this.state.highlightedIndex = index;
    const suggestion = this.state.filteredSuggestions[index];
    this.state.value = suggestion[1];
  }

  async actionKeyEnter() {
    if (this.state.highlightedIndex === -1) return;
    this.state.open = false;
    const suggestion = this.state.suggestions[this.state.filteredSuggestions[this.state.highlightedIndex][0]];
    if (suggestion && this.props.onSelect) this.props.onSelect(suggestion);
  }
}

const omitProps = ["suggestions", "inputRef", "onSelect", "renderSuggestion", "blankSuggestion"];

const AutocompleteControlled: React.FC<
  Omit<React.ComponentProps<typeof Input>, "ref" | "onSelect"> & { inputRef?: any }
> = (props) => {
  const controller = AutocompleteController.use();
  const { open, highlightedIndex, value } = controller.state;

  const { refs, floatingStyles } = useFloating({
    placement: "bottom-start",
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
  });

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    controller.actionSetValue(event.target.value);
    if (props.onChange) props.onChange(event);
  }, []);

  const handleSuggestionClick = useCallback((suggestion: IndexedSuggestion) => {
    controller.actionSelectSuggestion(suggestion);
  }, []);

  const handleInputKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case "ArrowUp":
          event.preventDefault();
          controller.actionKeyUp();
          break;
        case "ArrowDown":
          event.preventDefault();
          controller.actionKeyDown();
          break;
        case "Enter":
        case "Return":
          if (open) {
            event.preventDefault();
            controller.actionKeyEnter();
            return;
          }
      }

      if (props.onKeyDown) props.onKeyDown(event);
    },
    [open],
  );

  const handleListKeyDown = useCallback((event: KeyboardEvent<HTMLUListElement>) => {
    console.log("list key down", event.key);
  }, []);

  const handleBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      controller.actionClose();
    }, 100);

    if (props.onBlur) props.onBlur(event);
  }, []);

  return (
    <>
      <Input
        {...omit(props, omitProps as any)}
        value={value}
        onChange={handleInputChange}
        onFocus={() => {
          controller.actionFocus();
        }}
        onBlur={handleBlur}
        onKeyDown={handleInputKeyDown}
        ref={(el) => {
          if (props.inputRef) props.inputRef(el);
          refs.setReference(el);
        }}
        autoComplete="off"
        role="presentation"
      />
      {open && (
        <ul
          onKeyDown={handleListKeyDown}
          ref={refs.setFloating}
          className={`${
            open ? "opacity-100" : "pointer-events-none"
          } z-20 whitespace-nowrap bg-white text-sm shadow-lg ring-1 ring-black ring-opacity-5 transition-opacity focus:outline-none`}
          style={floatingStyles}
        >
          {controller.state.filteredSuggestions.map((suggestion, index) => (
            <li
              key={`autocomplete-suggestion-${suggestion[0]}`}
              onClick={() => handleSuggestionClick(suggestion)}
              className={cx(`w-full cursor-pointer p-1 odd:bg-gray-100 hover:bg-slate-700 hover:text-white`, {
                "bg-slate-700 text-white odd:bg-slate-700": index === highlightedIndex,
              })}
              role="option"
            >
              {suggestion[1]}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

const Scoped = AutocompleteController.scope(ApplicationView(AutocompleteControlled));

export function Autocomplete<T>(props: Props<T> & React.ComponentProps<typeof AutocompleteControlled>) {
  // @ts-ignore
  return <Scoped {...props} />;
}
