import clsx from "clsx";
import Color from "color";

const bgToTextColor: Record<string, string> = {
  "#F44336": "#fff",
  "#9C27B0": "#fff",
  "#2196F3": "#fff",
  "#4CAF50": "#fff",
  "#FFEB3B": "#000",
  "#FF5722": "#fff",
  "#9E9E9E": "#fff",
  "#3F51B5": "#fff",
  "#E91E63": "#fff",
  "#673AB7": "#fff",
  "#CDDC39": "#000",
  "#795548": "#fff",
  "#607D8B": "#fff",
  "#FFC107": "#000",
  "#009688": "#fff",
};

interface Props {
  color: string;
  className?: string;
  size: number;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const ColorDot: React.FC<Props> = ({ color, className, style, size, children }) => {
  const dotStyle = {
    backgroundColor: color,
    color: bgToTextColor[color] || (Color(color).isDark() ? "white" : "black"),
    ...style,
  };

  if (size) {
    dotStyle.height = `${size}px`;
    dotStyle.width = `${size}px`;
    dotStyle.fontSize = `${Math.floor(size * 0.66)}px`;
  }

  return (
    <div
      className={clsx("flex items-center justify-center rounded-full", className)}
      aria-roledescription="color dot"
      style={dotStyle}
    >
      <span className="font-bold opacity-80">{children}</span>
    </div>
  );
};

export { ColorDot };
