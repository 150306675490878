import { omit } from "@mixitone/util";
import React, { useEffect, useState } from "react";

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
export const TextArea = React.forwardRef((props: Props, ref: React.Ref<HTMLTextAreaElement>) => {
  const [value, setValue] = useState(props.value);
  const rest = omit(props, ["value", "onChange"]);

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    props.onChange(e);
  };

  return (
    <textarea
      ref={ref}
      value={value}
      onChange={handleChange}
      {...rest}
      className={`mt-1 block w-full rounded border border-gray-300 p-2 ${rest.className}`}
    />
  );
});
