import { Group } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import { ColorDot } from "./ColorDot";
import { omit } from "@mixitone/util";

type Props = Omit<React.ComponentProps<typeof ColorDot>, "color" | "children"> & {
  group: Group;
};

export const GroupDot = ApplicationView((props: Props) => {
  const group = props.group;
  const rest = omit(props, ["group"]);

  return (
    <ColorDot color={group?.asColor?.hex() || "black"} {...rest}>
      {group?.initial}
    </ColorDot>
  );
});
