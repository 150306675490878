import { ApplicationView } from "@mixitone/mvc";
import { PropsWithChildren } from "react";

interface FieldProps {
  className?: string;
}

const Field: React.FC<PropsWithChildren<FieldProps>> = ({ className, children }) => {
  return <div className={`mb-4 ${className}`}>{children}</div>;
};

export default ApplicationView(Field);
