import clsx from "clsx";
import useOutsideAlerter from "../hooks/useOutsideAlerter";
import ApplicationController, { ApplicationView } from "@mixitone/mvc";
import { useEffect, useId, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Spinner } from "./Spinner";

interface DrawerProps {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
  className?: string;
}

interface State {
  open: boolean;
}

class DrawerController extends ApplicationController<State, DrawerProps> {
  static override initialState: Partial<State> = {
    open: false,
  };

  override async initialize({ open }: DrawerProps) {
    this.setState({ open });
  }

  override async changeProps(newProps: DrawerProps) {
    this.setState({ open: newProps.open });
  }
}

const ControlledDrawer: React.FC<DrawerProps> = ({ onClose, children }) => {
  const controller = DrawerController.use();
  const { open } = controller.state;

  const drawerRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(
    drawerRef,
    () => {
      if (open) onClose();
    },
    { skipElements: ["[role=menu]", "[role=option]"] },
    [open, onClose],
  );

  const id = useId();
  const [drawerContainer, _setDrawerContainer] = useState(() => {
    const container = document.createElement("div");
    container.id = id;
    return container;
  });

  useEffect(() => {
    document.body.appendChild(drawerContainer);

    return () => {
      document.body.removeChild(drawerContainer);
    };
  }, [drawerContainer]);

  return createPortal(
    <div
      className={clsx(
        ["fixed", "right-0", "top-0", "z-40", "h-screen", "w-[500px]", "pt-10", "transition-transform"],
        {
          "translate-x-full": !open,
        },
      )}
    >
      <div
        className={clsx([
          "w-full",
          "h-full",
          "overflow-y-auto",
          "border-l",
          "border-t",
          "rounded-tl-lg",
          "border-slate-300",
          "bg-slate-100",
          "shadow-xl shadow-gray-400",
          "p-4",
        ])}
        tabIndex={-1}
        aria-labelledby="drawer-right-label"
        aria-roledescription="drawer"
        aria-hidden={!open}
        ref={drawerRef}
      >
        {children}
      </div>
    </div>,
    drawerContainer,
  );
};

const DrawerTitleComponent: React.FC<React.PropsWithChildren<{ icon?: React.ReactNode }>> = ({
  icon,
  children,
}) => {
  return (
    <div className="flex items-center mb-4 text-base font-semibold text-gray-800" role="heading">
      {icon || (
        <svg
          className="mr-2.5 h-4 w-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
      )}
      {children}
    </div>
  );
};
const DrawerTitle = ApplicationView(DrawerTitleComponent);
export { DrawerTitle };

const DrawerSpinnerComponent: React.FC = () => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <Spinner size={64} />
    </div>
  );
};
const DrawerSpinner = ApplicationView(DrawerSpinnerComponent);
export { DrawerSpinner };

const Drawer = DrawerController.scope(ApplicationView(ControlledDrawer));
export { Drawer };
