import { ColorDot } from "./ColorDot";

interface Props {
  colors: string[];
  size: number;
}

export const MultiDot: React.FC<Props> = ({ colors, size }) => {
  const overlap = size / 2;

  return (
    <div
      className="relative overflow-hidden"
      style={{
        height: `${size}px`,
        width: `${colors.length * size - (colors.length < 2 ? 0 : (colors.length - 1) * overlap)}px`,
      }}
    >
      {colors.map((color, index) => (
        <ColorDot
          key={`${color}-${index}`}
          color={color}
          className="absolute"
          size={size}
          style={{
            left: `${index * (size - overlap)}px`,
          }}
        />
      ))}
    </div>
  );
};
