import { Button, ButtonKind, Spinner, buttonClasses } from "./";
import { omit } from "@mixitone/util";
import React from "react";
import { twMerge } from "tailwind-merge";

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  kind?: ButtonKind;
  spinner?: boolean;
  className?: string;
  groupPosition?: React.ComponentProps<typeof Button>["groupPosition"];
}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const classes = buttonClasses(props.kind || "primary", twMerge(props.className, ""));

  return (
    <a {...omit(props, ["spinner", "groupPosition"])} onClick={props.onClick} className={classes} ref={ref}>
      <span className="inline-flex items-center gap-1">
        {props.spinner && <Spinner size={16} />}
        {!props.spinner && props.children}
      </span>
    </a>
  );
});

export { Link };
