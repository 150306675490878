import { omit } from "@mixitone/util";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  disabled?: boolean;
  small?: boolean; // New prop for size variant
}

export const Toggle = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const [checked, setChecked] = useState(props.checked);
  const rest = omit(props, ["checked", "onChange", "small"]);
  const error = props.error;
  const small = props.small;

  useEffect(() => {
    if (props.checked === checked) return;
    setChecked(props.checked);
  }, [props.checked]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    props.onChange(e);
  };

  return (
    <label className="relative inline-flex cursor-pointer items-center" role="checkbox">
      <input
        type="checkbox"
        className="peer sr-only"
        checked={checked}
        disabled={props.disabled}
        onChange={handleChange}
        {...rest}
        ref={ref}
      />
      <div
        className={clsx(
          "peer rounded-full",
          small ? "h-4 w-7" : "h-6 w-11", // Conditional sizing
          "after:absolute after:rounded-full after:border after:transition-all after:content-['']",
          small
            ? "after:left-[2px] after:top-[2px] after:h-3 after:w-3"
            : "after:left-[2px] after:top-[2px] after:h-5 after:w-5",
          "bg-gray-300",
          "after:border-gray-300 after:bg-white",
          "peer-checked:bg-brand-600",
          "peer-checked:after:border-white",
          "dark:border-gray-600 dark:bg-gray-700",
          "peer-checked:after:translate-x-full",
          "peer-focus:outline-none",
          "peer-checked:peer-disabled:bg-gray-300 peer-checked:peer-disabled:after:border-gray-400",
        )}
      ></div>
      {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
    </label>
  );
});
