import { EventCallback, EventName } from "@tauri-apps/api/event";
import { Tauri } from ".";
import { checkUpdate } from "@tauri-apps/api/updater";

export class RealTauri implements Tauri {
  constructor() {}

  get enabled(): boolean {
    return true;
  }

  invoke<T>(command: string, args?: Record<string, any>): Promise<T> {
    try {
      const retVal = window.__TAURI__!.tauri.invoke(command, args);
      return retVal;
    } catch (e) {
      console.error(`Error invoking tauri command ${command}`, e);
      throw e;
    }
  }

  emit(event: string, data: any) {
    return window.__TAURI__?.event.emit(event, data);
  }

  listen<Event>(event: EventName, callback: EventCallback<Event>) {
    return window.__TAURI__!.event.listen(event, callback);
  }

  checkUpdate() {
    return checkUpdate();
  }
}
