import { useEffect } from "react";

const keyMap = new Map<string, number>();

export default function useGlobalShortcut(
  key: string,
  callback: (event: KeyboardEvent) => void,
  options: { active?: boolean; overrides?: boolean } = {},
  deps?: any[],
) {
  const { active = true } = options;

  useEffect(() => {
    if (!keyMap.has(key)) keyMap.set(key, 0);

    if (!active) return;
    if (options.overrides) keyMap.set(key, keyMap.get(key)! + 1);
    let me = keyMap.get(key);

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === key && me === keyMap.get(key)) {
        callback(event);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      if (options.overrides) keyMap.set(key, (keyMap.get(key) || 1) - 1);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [key, callback, active, options.overrides, ...(deps || [])]);
}
