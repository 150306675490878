import { EventCallback, EventName } from "@tauri-apps/api/event";
import { checkUpdate } from "@tauri-apps/api/updater";
import { MockTauri } from "./MockTauri";
import { NullTauri } from "./NullTauri";
import { RealTauri } from "./RealTauri";

export interface InvokePayload {
  cmd: string;
  payload: Record<string, any>;
  id: number;
}

export interface Tauri {
  get enabled(): boolean;
  invoke<T>(command: string, args?: Record<string, any>): Promise<T>;
  emit(event: string, data: any): void;
  listen<Event>(event: EventName, callback: EventCallback<Event>): Promise<Function>;
  checkUpdate(): ReturnType<typeof checkUpdate>;
}

const mock = import.meta.env.VITE_TAURI_TEST === "1";
let tauri: Tauri;

if (typeof window !== "undefined") {
  if (mock) tauri = new MockTauri();
  else if (window.__TAURI__) tauri = new RealTauri();
  else tauri = new NullTauri();

  // @ts-ignore
  window.tauri = tauri;
} else {
  tauri = new NullTauri();
}

export default tauri;
