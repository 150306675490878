import { ColorDot } from "./ColorDot";
import { twMerge } from "tailwind-merge";

const defaultColors = [
  "#F44336",
  "#9C27B0",
  "#2196F3",
  "#4CAF50",
  "#FFEB3B",
  "#FF5722",
  "#9E9E9E",
  "#3F51B5",
  "#E91E63",
  "#673AB7",
  "#CDDC39",
  "#795548",
  "#607D8B",
  "#FFC107",
  "#009688",
];

/**
 * Overlapping coloured dots
 */
export const AllDot: React.FC<{ className?: string; size?: number }> = ({ className, size }) => {
  const cssSize = `${size || 12}px`;

  const colors = defaultColors;
  const paths = [
    "polygon(50% 50%, 100% 100%, 50% 100%)",
    "polygon(50% 50%, 100% 100%, 100% 50%)",
    "polygon(50% 50%, 100% 50%, 100% 0%)",
    "polygon(50% 50%, 100% 0%, 50% 0%)",
    "polygon(50% 50%, 50% 0%, 0% 0%)",
    "polygon(50% 50%, 0% 0%, 0% 50%)",
    "polygon(50% 50%, 0% 50%, 0% 100%)",
    "polygon(50% 50%, 0% 100%, 50% 100%)",
  ];

  const dots = paths.map((path, index) => {
    const color = colors[index];
    return (
      <ColorDot
        key={color}
        color={color}
        className="absolute top-0 left-0"
        size={size || 12}
        style={{
          clipPath: path,
        }}
      />
    );
  });

  return (
    <div
      className={twMerge("relative", className)}
      style={{
        width: cssSize,
        height: cssSize,
      }}
    >
      {dots}
    </div>
  );
};
