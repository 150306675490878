import { ApplicationView } from "@mixitone/mvc";
import Field from "./Field";
import { PropsWithChildren } from "react";
import { Label, LabelProps } from "./Label";

type Props = LabelProps & {
  label: React.ReactNode;
  helpText?: React.ReactNode;
  inline?: boolean;
};

const ControlledLabelledField: React.FC<PropsWithChildren<Props>> = (props) => {
  const { label, helpText, children, inline, ...labelProps } = props;
  const className = inline ? "flex items-center justify-between" : "";

  return (
    <Field>
      <div className={className}>
        <Label {...labelProps}>{label}</Label>
        {children}
      </div>
      {helpText && <p className="mt-1 text-xs text-gray-500">{helpText}</p>}
    </Field>
  );
};

const LabelledField = ApplicationView(ControlledLabelledField);
export { LabelledField };
