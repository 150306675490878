export interface Point {
  x: number;
  y: number;
}

export const prefersReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)");

export const getElementCenter = (el: Element): Point => {
  const rect = el.getBoundingClientRect();
  return { x: rect.left + rect.width / 2, y: rect.top + rect.height / 2 };
};

export const getElementTopLeft = (el: Element): Point => {
  const rect = el.getBoundingClientRect();
  return { x: rect.left, y: rect.top };
};

export async function animateMoveTo(
  el: HTMLElement,
  fromPoint: Point,
  toPoint: Point,
  options: KeyframeAnimationOptions & { hideAfter?: boolean },
) {
  el.style.transition = "none";
  el.style.visibility = "visible";

  await new Promise((resolve, _reject) => {
    el.animate(
      [
        { transform: `translate(${fromPoint.x}px, ${fromPoint.y}px)` },
        { transform: `translate(${toPoint.x}px, ${toPoint.y}px)` },
      ],
      {
        duration: 500,
        easing: "ease",
        ...options,
      },
    ).onfinish = resolve;
  });
  if (options.hideAfter) {
    el.style.visibility = "hidden";
  }
}

export function animateFadeTo(
  el: HTMLElement,
  fromOpacity: number,
  toOpacity: number,
  options: KeyframeAnimationOptions,
) {
  return new Promise((resolve, _reject) => {
    el.animate([{ opacity: fromOpacity }, { opacity: toOpacity }], {
      duration: 100,
      fill: "forwards",
      ...options,
    }).onfinish = resolve;
  });
}
