import { EventCallback, EventName } from "@tauri-apps/api/event";
import { Tauri } from ".";
import { checkUpdate } from "@tauri-apps/api/updater";

export class NullTauri implements Tauri {
  get enabled() {
    return false;
  }

  invoke<T>(command: string, args?: Record<string, any>): Promise<T> {
    throw new Error("Tauri is not enabled");
  }

  emit(event: string, data: any) {
    throw new Error("Tauri is not enabled");
  }

  listen<Event>(event: EventName, callback: EventCallback<Event>): Promise<Function> {
    throw new Error("Tauri is not enabled");
  }

  checkUpdate(): ReturnType<typeof checkUpdate> {
    throw new Error("Tauri is not enabled");
  }
}
