import Group from "@mixitone/models/Group";
import { ApplicationView } from "@mixitone/mvc";
import { AllDot } from "./AllDot";
import { MultiDot } from "./MultiDot";
type Props = {
  group: Group;
  groups: Group[];
};

const ControlledGroupExcludes: React.FC<Props> = ({ group, groups }) => {
  return (
    <>
      {group.excludesGroups.length === 0 && <AllDot size={20} />}
      {group.excludesGroups.length > 0 && (
        <MultiDot
          size={20}
          colors={groups
            .filter((g) => !group.excludesGroups.includes(g.uniqueId))
            .map((g) => g.asColor.hex())}
        />
      )}
    </>
  );
};

const GroupExcludes = ApplicationView(ControlledGroupExcludes);
export { GroupExcludes };
